const MenuData = {
  zibu: {
    adventures: { text: 'Adventures of zibu', link: '/#adventures' },
    tokenomics: { text: 'Tokenomics', link: '/#tokenomics' },
    howBuy: { text: 'How To Buy', link: '/#howBuy' },
    joinUs: { text: 'Join Us', link: '/#joinUs' }
  },
  volt: {
    payment: { text: 'Payment Cycle Voting System', link: '/volt/#payment' },
    chain: { text: 'The Chain', link: '/volt/#chain' },
    roadmap: { text: 'Roadmap', link: '/volt/#roadmap' },
    dashboard: { text: 'Dashboard', link: 'http://dashboard.zibu.io/' },
    faq: { text: 'FAQ', link: '/volt/#faq' },
    joinUs: { text: 'Join Us', link: '/volt/#joinUs' },
  }
}

export default MenuData;
